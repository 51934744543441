<template>
  <!-- Make sure parent using this component should have appropriate height -->
  <div class="d-flex flex-column fill-height">
  <div>
    <v-row class="px-3">
      <v-col :cols="$vuetify.breakpoint.xs ? 12 : 10" class="py-2">
        <v-select :items="menuList" background-color="white" item-text="label" item-value="key" outlined dense hide-details v-model="activeTab"
        v-if="this.menuList.length > 1">
      </v-select>
      </v-col>
      <v-col cols="2" class="py-2" v-if="!$vuetify.breakpoint.xs">
        <HbBtn type="icon" active-state-off tooltip="Filter" :active="filterModal" @click="openFilterModal" >
                  <template >
                    <v-badge
                      color="#F26500"
                      dot
                      :value="isFilterActive"
                    >
                      <hb-icon
                      >mdi-filter-variant</hb-icon>
                    </v-badge>
                  </template>
      </HbBtn> 
      </v-col >
    </v-row>
    
    <v-tabs
        v-if="$vuetify.breakpoint.xs"
        background-color="#FFF"
        class="hb-text-light px-4"
        color="#101318"
        v-model="tab"
        show-arrows
    >
        <v-tabs-slider color="#00848E"></v-tabs-slider>
        <v-tab
            :key="'communication'"
            class="text-capitalize"
            :ripple="false"
            @click="tabChange(0)"
        >
          Incoming
        </v-tab>
        <v-tab
            :key="'tasks'"
            class="text-capitalize"
            :ripple="false"
            @click="tabChange(1)"
        >
            Outgoing
        </v-tab>
    </v-tabs>
    <v-divider v-if="$vuetify.breakpoint.width <= 800"></v-divider>
    <v-row class="px-4 bg-gray" :class="{'mx-0':$vuetify.breakpoint.width > 800}">
      <v-col cols="12" class="pt-2 pb-2">
        <HbTextField
          search
          placeholder="Search Tenant or Space"
          @input="search"
          v-model="searchParam"
          clearable
          @click:clear="clear"
        />
      </v-col>
    </v-row>
    <v-divider v-if="$vuetify.breakpoint.width > 800" ></v-divider>
  </div>
  <div class="hb-communication-list-container bg-gray">
    <v-row class="px-3 fill-height user-list-container">
      <v-col cols="12" class="user-list px-0 py-0 fill-height" @scroll.self="fetchOnScroll">
        <v-row no-gutters v-for="(r, index) in filteredList" :key="'multiple_sync_' + index" class="mb-3 justify-center">
          <hb-communication
            :preview="true"
            :contact-name="r.first + ' ' + r.last"
            :elevation="index == activeIndex"
            :active="index == activeIndex"
            :unread="r.has_unread == 1"
            :contact-status="r.status"
            :outgoing="hbSetOutgoingFlag(r)"
            :type="hbSetType(r).type"
            :time="hbDateFormat(r.last_message_time)"
            :property="r.last_interaction_phone_call_property_name"
            :source="r.last_interaction_phone_call_source"
            :subject="r?.email_subject || ''"		
            :delivered-by="r.last_message_method_name"
            :contact-is-business="r.rent_as_business"
            @click="showDetails(r,index)"
            >
            <div v-html="hbSetType(r)?.last_message_text || ''" ></div>
          </hb-communication>
          </v-row>
          <v-row class="mx-0" v-if="isLoading($options.name)">
            <v-col md="12" class="text-center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-col>
          </v-row>
      </v-col>
    </v-row>
    </div>
    <hb-modal title="Filter" v-model="filterModal" :footerCancelOption="false" @close="onCloseModal()">
          <template v-slot:subheader>
           Filter your communications here.
          </template>
            <template v-slot:content>
                <!-- <hb-form label="Read/Unread" full>
                  <HbCheckbox
                  v-model="filterData.read"
                  label="Read"
                  />
                  <HbCheckbox
                  v-model="filterData.unread"
                  label="Unread"
                  />
                </hb-form> -->
                <hb-form label="Communications" full>
                  <HbCheckbox
                  v-model="filterData.incoming"
                  label="Incoming"
                  />
                  <HbCheckbox
                  v-model="filterData.outgoing"
                  label="Outgoing"
                  />   
                  <!-- <HbCheckbox
                  v-model="filterData.systemGenerated"
                  label="System Generated"
                  /> -->
                </hb-form>
                <!-- <hb-form label="Date range" full>
                  <div class="mt-4 mb-2">
                    <HbSelect
                    v-model="filterData.selectedDay"
                    :items="['Today', 'Yesterday', 'Custom Range']"
                    v-validate="'required'"
                    data-vv-scope="default"
                    data-vv-name="timeframe"
                    data-vv-as="timeframe"
                    :error="errors.has('default.timeframe')"
                    placeholder="Custom Timeframe"
                />
                  <v-row v-if="filterData.selectedDay === 'Custom Range'">
                    <v-col>
                      <HbDatePicker
                      v-model="filterData.start_date"
                      @click:clear="filterData.start_date = null"
                      v-validate="'required'"
                      label="Start date"
                      :clearable="true"
                      clear-icon="mdi-close-circle"
                      :solo="false"
                      :single-line="true"
                      data-vv-scope="general"
                      data-vv-name="start_date"
                      name="start_date"
                      data-vv-as="Start date"
                      dense
                      :error="errors.collect('general.start_date').length > 0"
                  />
                    </v-col>
                    <v-col>
                      <HbDatePicker
                      v-model="filterData.end_date"
                      @click:clear="filterData.end_date = null"
                      v-validate="'required'"
                      label="End date"
                      :clearable="true"
                      :min="filterData?.start_date || date"
                      clear-icon="mdi-close-circle"
                      :solo="false"
                      :single-line="true"
                      data-vv-name="end_date"
                      data-vv-as="End date"
                      :error="errors.collect('general.end_date').length > 0"
                      dense
                      default_today
                      :disabled="!filterData.start_date"
                  />
                    </v-col>
                  </v-row>
              </div>
            </hb-form> -->
        
            </template>
          <template v-slot:right-actions>
            <hb-link color="tertiary" @click="clearForm()">Clear Filters</hb-link>
            <hb-btn @click="applyFilter()" :loading="isFilterLoading" :disabled="!isButtonEnable">Apply</hb-btn>
        </template>
      </hb-modal>
</div>
</template>
<script>


import api from '../../assets/api.js';
import { EventBus } from '../../EventBus.js';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment'
import HbDatePicker from '../assets/HummingbirdDatepicker.vue';
import NOTICE_MANAGER from '@/constants/notice_manager.js'; 


export default {
  name:'TenantAndLeadsList',
  data () {
    return {
      activeTab: 0,
      inOrOutTab: 0,
      searchParam: '',
      rpost_delivery_method: NOTICE_MANAGER.RPOST.RPOST_DELIVERY_METHOD,
	  currentOffset: 0,
      allResult: [],
      filteredList:[],
      menuList: [
        { key: 0, label: 'Latest Messages' },
        { key: 1, label: 'Leads' },
        { key: 2, label: 'Tenants' },
        { key: 3, label: 'All Communications' }
      ],
      menuOptions: [
        { label: 'Incoming', key: 'incoming' },
        { label: 'Outgoing', key: 'outgoing' },
      ],
      activeContact: '',
      threadStyle:{
        height: '350px',
      },
      filter: false,
      // isFilterActive:false,
      filterModal: false,
      filterData :{
      start_date: '',
      end_date: '',
      selectedDay : '',
      read: '',
      unread: '',
      incoming: this.$vuetify.breakpoint.xs ? true : '',
      outgoing: this.$vuetify.breakpoint.xs ? false : '',
      },
      InitialfilterData :{
        start_date: '',
        end_date: '',
        selectedDay : '',
        read: '',
        unread: ''
      },
      isFilterLoading: false,
      timeOut : null,
      isAllRecordsLoaded: false,
      activeIndex: this.$vuetify.breakpoint.width < 800 ? -1 : 0,
      direction: 'incoming',
      dense:false,
      tab: 0
      }
  },
  watch:{
    async activeTab () {
      this.currentOffset = 0;
      this.filteredList = [];
      await this.fetchData();
      this.displayContactDetails(this.$vuetify.breakpoint.width <= 800 ? -1 : 0);
    },
    contact_id(newVal) { 
      let contactDetail = this.findContactPlaceInList(newVal);
      this.displayContactDetails(contactDetail);
    },
    updatedInteractor(newval) {
  
      let index = this.filteredList.findIndex(r => r.id == this.updatedInteractor.contact_id);

      if(index > -1) {
        this.filteredList[index].has_unread = this.updatedInteractor.has_unread;
        this.filteredList[index].last_message_time = this.updatedInteractor.last_message_time;
        this.filteredList[index].last_message_text = this.updatedInteractor.last_message_text;
        this.filteredList[index].responded = this.updatedInteractor.responded;
      }

   },
   async refreshData() {
    
      if (this.refreshData) {
         this.filteredList = [];
		 this.currentOffset = 0;
        await this.fetchData();
        this.displayContactDetails(this.$vuetify.breakpoint.width <= 800 ? -1 : 0);
        this.$emit('onOpen')
      }
    },
    // BCT:navigate to particular profile index
    callWrapAction() {
      if(this.callWrapAction){
        setTimeout(() => {
          let contactDetail = this.findContactPlaceInList(this.contact_id);
          this.displayContactDetails(contactDetail);
        }, 500);
      }
    }
 },
 components: {
    HbDatePicker
  },
 mounted () {
    if(this.$vuetify.breakpoint.xs) {
      this.filterData.incoming = true;  
      this.filterData.outgoing = false;
    }
 },
 computed: {
  isButtonEnable() {
      if (this.filterData) {
        if (this.filterData.selectedDay === 'Custom Range') {
          if (this.filterData.start_date && this.filterData.end_date)
            return true;
          else
            return false
        }
        else if (this.filterData.selectedDay) {
          return true
        }
        else if (this.filterData.read || this.filterData.unread ){
          return true;
        }  
        else if (this.filterData.incoming || this.filterData.outgoing || this.filterData.systemGenerated){
          return true;
        }
        else
          return false;
      }
    },
    isFilterActive() {
      // Check if any of the filter criteria are set
      console.log(this.filterData,"filterdata");
      return Object.values(this.filterData).some(value => value !== '')
    },
    ...mapGetters({
      activeDrawerComponent: 'navigationStore/activeDrawerComponent',
      callWrapAction:"charmCallStore/callWrapData"
    }),
 },
  async created () {
    if(this.activeDrawerComponent.type === 'show_communication_center'){
      this.contact_id = this.activeDrawerComponent.contact_id;
      this.searchParam = this.activeDrawerComponent.search || '';
    }
    await this.fetchData();

    if (this.contact_id) {
      let contactDetail = this.findContactPlaceInList(this.contact_id);
      this.displayContactDetails(contactDetail);
    } else {
      this.displayContactDetails(this.$vuetify.breakpoint.width <= 800 ? -1 : 0);
    }
    
    

    EventBus.$on('communication::refresh', this.fetchData);
    this.$root.$on('refresh_contacts', this.fetchData)

    this.setChatDimensions()

    // window.addEventListener('resize', _.debounce(() => {
    //   this.setChatDimensions();
    // }, 350));
  },
  beforeDestroy () {
    EventBus.$off('communication::refresh', this.fetchData);
    this.$root.$off('refresh_contacts', this.fetchData)
  },
  methods:{
    async clearForm() {
      this.filterData.start_date = ''
      this.filterData.end_date = ''
      this.filterData.selectedDay = ''
      this.filterData.read = ''
      this.filterData.unread = ''
      this.filterData.incoming = ''
      this.filterData.outgoing = ''
      this.filterData.systemGenerated = ''
      await this.fetchData(true);
      this.filterModal = false
    },
    async tabChange(tab) {
      this.tab = tab;
      if(tab == 0) {
        this.filterData.incoming = true;
        this.filterData.outgoing = false;
        await this.fetchData(true);
      } else {
        this.filterData.outgoing = true;
        this.filterData.incoming = false;
        await this.fetchData(true);
      }
    },
    async applyFilter() {
      if (this.filterData) {
        this.isFilterLoading = true
        try {
          // api filter
          await this.fetchData(true);
        } catch (error) {
          this.isFilterLoading = false;
        } finally {
          this.isFilterLoading = false;
        }
        this.filterModal = false
      }
    },
    openFilterModal() {
      this.filterModal = true
      this.InitialfilterData = {
        start_date: this.filterData.start_date,
        end_date: this.filterData.end_date,
        selectedDay: this.filterData.selectedDay,
        read: this.filterData.read,
        unread: this.filterData.unread
      }
    },
    onCloseModal() {
      this.filterModal = false
      this.filterData = this.InitialfilterData
    },
    ...mapActions({
      setActiveDrawerComponent:'navigationStore/setActiveDrawerComponent',
    }),
    findContactPlaceInList(contact_id) {
      if(!contact_id) return;

      let contactPlace = 0
      for (let contactPlaceInList = 0; contactPlaceInList < this.filteredList.length; contactPlaceInList++) {
        if (contact_id === this.filteredList[contactPlaceInList].id) {
          contactPlace = contactPlaceInList;
        }
      }

      return contactPlace;
    },
    displayContactDetails(index) {
      console.log('index.........................', index);
      this.filteredList && this.showDetails(this.filteredList[index]);
    },
    fetchOnScroll({ target: { scrollTop, clientHeight, scrollHeight }}){
        if (scrollTop + clientHeight >= scrollHeight) {
              // if ((this.filteredList.length%20) === 0 && !this.isLoading(this.$options.name) && !this.isAllRecordsLoaded) {
              if ((this.currentOffset%20) === 0 && !this.isLoading(this.$options.name) && !this.isAllRecordsLoaded) {
                this.fetchData();
            }
        }
    },
    async fetchData(refresh){
      //let offset = this.filteredList.length;
	  let offset = this.currentOffset;
      let limit = 20;

      if(refresh){
        offset = 0;
		this.currentOffset = 0;
        limit = this.filteredList.length;
        
      }
      let path = `interactors?limit=${20}&offset=${offset}&search=${this.searchParam || ''}`;
      switch (this.activeTab) {
        case 0: 
          path = path + '&type=latest';
          break;
        case 1:
          path = path + '&type=leads';
          break;
        case 2:
          path = path + '&type=tenant';
          break;
      }
      if (this.filterData) {
        if (this.filterData.selectedDay == 'Custom Range') {
          if (this.filterData.start_date !== '') {
            path += `&start_date=${this.filterData.start_date}`;
          }
          if (this.filterData.end_date !== '') {
            path += `&end_date=${this.filterData.end_date}`;
          }
        }
        else if (this.filterData.selectedDay) {
          path += `&selectedDay=${this.filterData.selectedDay}`;
        }
        if(! (this.filterData?.read && this.filterData?.unread)  ){
          if (this.filterData.read) {
            path += `&read=1`;
          }
  
          if (this.filterData.unread) {
            path += `&read=0`;
          }

        }
        if (this.filterData.incoming && !this.filterData.outgoing) {
            path += `&delivery=incoming`;
        }
        if (this.filterData.outgoing && !this.filterData.incoming) {
            path += `&delivery=outgoing`;
        }
      }
      if (this.refreshData) {
        this.$emit('onClose')
      }

      await api.get(this, api.CONTACTS  + path).then(r => {
        
        r.contacts = r.contacts.map(c => {
          c.Phones = [{
            id: c.phone_id,
            primary: true,
            phone: c.phone
          }];

          let is_address_exists =  !!(c.address && c.city && c.zip);
          if(is_address_exists)
            c.Addresses = [{
              Address: {
                address: c.address,
                address2: c.address2,
                city: c.city,
                zip: c.zip
              }
            }]
          return c;
        })
        
        if(refresh){
          this.filteredList = [];
		  this.currentOffset = 0;
        }
        this.currentOffset = this.currentOffset + 20;
		
        this.filteredList = [...this.filteredList, ...r.contacts];
        if (r.contacts.length) {
          this.isAllRecordsLoaded = false;
        } else {
          this.isAllRecordsLoaded = true
        }
      });

      // if((this.filteredList.length < 20)){
	     // if (!this.searchParam || !this.activeTab === 1) {
           // this.fetchData()
		 // }
      // }
    },

 async showDetails (interactionObj,index) {
      if(this.$vuetify.breakpoint.width > 800) {
       index = index || 0;
      }

      this.activeIndex = index;
      this.activeContact = interactionObj?.id;
      this.$emit('showInteraction', interactionObj);
      if(this.activeDrawerComponent.type === 'show_communication_center'){
        this.setActiveDrawerComponent({
          type:'show_communication_center',
          contact_id:interactionObj.id,
          search: this.activeDrawerComponent.search || ''
        });
      }
    },

    search: _.debounce(async function(event, force) {
        this.filteredList = [];
		this.currentOffset = 0;
        if(this.activeDrawerComponent.type === 'show_communication_center'){
          this.setActiveDrawerComponent({type:'show_communication_center', search: this.searchParam});
        }
        this.fetchData(true,this.direction);
    }),

    clear(){
      this.searchParam = '';
      this.search(null, true)
    },

    getWindowSize(){
      var w=window,d=document,e=d.documentElement,g=d.getElementsByTagName('body')[0],x=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
      return {x,y}
    },
    setChatDimensions() {
      var windowSize = this.getWindowSize()
      var height = 0;
      if(windowSize.x <= 767) {
        height = windowSize.y - 368;
      } else {
        height = windowSize.y  - 329;
      }
      this.threadStyle.height = height + 'px';
    },
    hbSetType(r) {
      if (r.last_message_method === 'email' && r.last_message_method_name != this.rpost_delivery_method) {
        if (r.id === r.last_message_entered_by_id) {
          return { type: 'email in' }
        } else if (r.last_message_entered_by_id === null) {
          // system generated 
          return { type: 'email out' }
        }
        else {
          return { type: 'email out' }
        }
      }
      else if (r.last_message_method === 'sms') {
        if (r.id === r.last_message_entered_by_id) {
          return { type: 'text in',last_message_text: r.last_message_text }
        } else {
          return { type: 'text out',last_message_text: r.last_message_text }
        }
      }
      else if (r.last_message_method === 'phone_call') {
        if (r.last_interaction_phone_call_direction === 'outgoing') {
          return { type: 'call out' }
        }
        else if (r.last_interaction_phone_call_recording_url && r.last_interaction_phone_call_status == 'no-answer') {
          return { type: 'voicemail' }
        }
        else if (!r.last_interaction_phone_call_recording_url && (r.last_interaction_phone_call_status == 'no-answer' || r.last_interaction_phone_call_status == 'cancelled')) {
          return { type: 'missed' }
        }
        else {
          if(r.last_message_entered_by_id && (r.last_message_entered_by_id != r.id) && !r.last_interaction_phone_call_direction){
            return { type: 'call out' }
          }else{
            return { type: 'call in' }
          }
        }
      }
      else if (r.last_message_method === 'mail' || r.last_message_method_name === this.rpost_delivery_method) {
        return { type: 'document'}
      }
      else if (r.last_message_method === null) {
        return { type: 'note' }
      }


    },
    hbSetOutgoingFlag(r){
      if (r.last_message_method === 'email') {
        if (r.id === r.last_message_entered_by_id) {
          return false
        } else {
          return true
        }
      }
      else if (r.last_message_method === 'sms') {
        if (r.id === r.last_message_entered_by_id) {
          return false
        } else {
          return true
        }
      }
      else if (r.last_message_method === 'phone_call') {
        if (r.last_interaction_phone_call_direction === 'outgoing') {
          return true
        }
        else {
          return false
        }
      }else if(r.last_message_method === 'mail'){
        return false
      }
    },
    hbDateFormat(dateTime){
      const daysDiff = moment.utc(dateTime).local().calendar();
      const targetDate = moment.utc(dateTime).local();
      if (daysDiff.split(' ')[0] === 'Today') {
        return `Today, ${targetDate.format('h:mma')}`;
      } else if (daysDiff.split(' ')[0] === 'Yesterday') {
        return `Yesterday, ${targetDate.format('h:mma')}`;
      } else {
        return targetDate.format('MMM D, YYYY @ h:mma');
      }
    },
   
  
  },
  props: ["updatedInteractor", "refreshData", "onOpen", "onClose", "contact_id"],
}
</script>
<style scoped>

.user-list {
  padding: 16px!important;
}

@media (max-width: 800px) {
  .hb-communication-list-container >>> .hb-communication-wrapper-preview {
    max-width: 100%;
  }
  .user-list {
    padding: 0px 16px 16px 16px!important;
  }
}
</style>
<style lang="scss" scoped>
.hb-communication-list-container {
  position: relative;
  flex-grow: 1;
}
.user-list-container {
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right: 0;
}
.user-list {
  overflow-x:hidden;
  overflow-y: scroll;
  
}

.tab-sizing {
  min-width: 10px;
}

.text-display {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.timeline-text {
    font-size: 12px;
    color: #637381;
}

.user-list::-webkit-scrollbar {
  display: none;
}

.nameAndDate {
  padding-bottom: 5px;
}

/* Hide scrollbar for IE and Edge */
.user-list {
  -ms-overflow-style: none;
}
  .active {
    background-color: #E0F5F5;
  }

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #ffffff !important;
    // min-height: 0px;
}

.bg-gray {
  background-color: #F9FAFB !important;
}

</style>
